<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('assets_info.assets_info') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="row">
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('assets_info.name') }}<span class="text-danger">*</span></label>
                <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.name[0] }}
                                    </span>
              </div>
            </div>
          </div>
          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('assets_info.category_account') }}
                  <!-- <span class="text-danger">*</span> -->
                </label>
                <!--                                    <treeselect-->
                <!--                                            :options="accounts_list"-->
                <!--                                            :load-options="loadOptions"-->
                <!--                                            :multiple="false"-->
                <!--                                            :value="data.account_id"-->
                <!--                                            @input="updateValue"-->
                <!--                                            :searchable="true"-->
                <!--                                            :class="validation && validation.account_id ? 'is-invalid' : ''"-->
                <!--                                            :show-count="true"-->
                <!--                                            :no-children-text="$t('No_sub_options')"-->
                <!--                                            :no-options-text="$t('No_options_available')"-->
                <!--                                            :no-results-text="$t('No_results_found')"-->
                <!--                                            :placeholder="$t('Select')">-->
                <!--                                    </treeselect>-->
                <multiselect
                    v-model="account"
                    @input="updateValueAccount"
                    :placeholder="$t('assets_info.category_account')"
                    label="label"
                    track-by="id"
                    :class="validation && validation.account_id ? 'is-invalid' : ''"
                    :options="accounts_leaf_list"
                    :multiple="false"
                    :taggable="false"
                    :show-labels="false"
                    :show-no-options="false"
                    :show-no-results="false"
                    @search-change="getAccounts($event)">
                </multiselect>
                <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                          {{ validation.account_id[0] }}
                  </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('assets_info.cash_account') }}</label>
                <div class="input-group">
                  <!--                                        <treeselect-->
                  <!--                                                :options="accounts_list"-->
                  <!--                                                :load-options="loadOptions"-->
                  <!--                                                :multiple="false"-->
                  <!--                                                :value="data.cash_account_id"-->
                  <!--                                                @input="updateValueCash"-->
                  <!--                                                :searchable="true"-->
                  <!--                                                :class="validation && validation.cash_account_id ? 'is-invalid' : ''"-->
                  <!--                                                :show-count="true"-->
                  <!--                                                :no-children-text="$t('No_sub_options')"-->
                  <!--                                                :no-options-text="$t('No_options_available')"-->
                  <!--                                                :no-results-text="$t('No_results_found')"-->
                  <!--                                                :placeholder="$t('Select')">-->
                  <!--                                        </treeselect>-->
                  <multiselect
                      v-model="cash_account"
                      @input="updateValueCash"
                      :placeholder="$t('assets_info.cash_account')"
                      label="label"
                      track-by="id"
                      :class="validation && validation.cash_account_id ? 'is-invalid' : ''"
                      :options="accounts_leaf_list"
                      :multiple="false"
                      :taggable="false"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false"
                      @search-change="getAccounts($event)">
                  </multiselect>
                  <span v-if="validation && validation.cash_account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.cash_account_id[0] }}
                                    </span>
                </div>
              </div>
            </div>
          </div>
          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('assets_info.tax_1') }}</label>
                <div class="input-group">
                  <multiselect v-model="tax1"
                               :class="validation && validation.tax1_id ? 'is-invalid' : ''"
                               :placeholder="$t('assets_info.tax_1')"
                               label="name"
                               track-by="id"
                               :options="taxes"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getTaxes($event)">
                  </multiselect>
                  <span v-if="validation && validation.tax1_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.tax1_id[0] }}
                                    </span>
                </div>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('assets_info.tax_2') }}</label>
                <div class="input-group">
                  <multiselect v-model="tax2"
                               :class="validation && validation.tax2_id ? 'is-invalid' : ''"
                               :placeholder="$t('assets_info.tax_2')"
                               label="name"
                               track-by="id"
                               :options="taxes"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getTaxes($event)">
                  </multiselect>
                  <span v-if="validation && validation.tax2_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.tax2_id[0] }}
                                    </span>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('assets_info.purchase_date') }}</label>
                <input type="date" v-model="data.purchase_date" class="form-control" :class="validation && validation.purchase_date ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.purchase_date" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.purchase_date[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('assets_info.in_service_date') }}</label>
                <input type="date" v-model="data.in_service_date" class="form-control" :class="validation && validation.in_service_date ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.in_service_date" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.in_service_date[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('assets_info.purchase_amount') }}<span class="text-danger">*</span></label>
                <div class="input-group" :class="((validation && validation.purchase_amount) || (validation && validation.purchase_amount_currency_id)) ? 'is-invalid' : ''">
                  <input type="number" min="1" v-model="data.purchase_amount" class="form-control" :class="validation && validation.purchase_amount ? 'is-invalid' : ''"/>
                  <select name="" id="purchase_amount_currency_id" v-model="data.purchase_amount_currency_id" class="custom-select" :class="validation && validation.purchase_amount_currency_id ? 'is-invalid' : ''">
                    <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                  </select>
                </div>
                <span v-if="validation && validation.purchase_amount" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.purchase_amount[0] }}
                                    </span>
                <span v-if="validation && validation.purchase_amount_currency_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.purchase_amount_currency_id[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('assets_info.salvage_value') }}<span class="text-danger">*</span></label>
                <div class="input-group" :class="((validation && validation.salvage_value) || (validation && validation.salvage_value_currency_id)) ? 'is-invalid' : ''">
                  <input type="number" min="1" v-model="data.salvage_value" class="form-control" :class="validation && validation.salvage_value ? 'is-invalid' : ''"/>
                  <select name="" id="salvage_value_currency_id" v-model="data.salvage_value_currency_id" class="custom-select" :class="validation && validation.salvage_value_currency_id ? 'is-invalid' : ''">
                    <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                  </select>
                </div>
                <span v-if="validation && validation.salvage_value" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.salvage_value[0] }}
                                    </span>
                <span v-if="validation && validation.salvage_value_currency_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.salvage_value_currency_id[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('assets_info.location') }}</label>
                <input type="text" v-model="data.location" class="form-control" :class="validation && validation.location ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.location" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.location[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('assets_info.useful_life') }}</label>
                <input type="number" min="1" v-model="data.useful_life" class="form-control" :class="validation && validation.useful_life ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.useful_life" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.useful_life[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('assets_info.employee') }}</label>
                <multiselect v-model="employee"
                             :placeholder="$t('assets_info.employee')"
                             :class="validation && validation.employee_id ? 'is-invalid' : ''"
                             label="full_name"
                             track-by="id"
                             :options="employees"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @search-change="getEmployees($event)">
                </multiselect>
                <span v-if="validation && validation.employee_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.employee_id[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('assets_info.depreciation_method') }}<span class="text-danger">*</span></label>
                <select name="" id="depreciation_method" v-model="data.depreciation_method" class="custom-select" :class="validation && validation.depreciation_method ? 'is-invalid' : ''">
                  <option v-for="row in depreciation_method_list" :value="row.id" :key="row.id">
                    {{ row.title }}
                  </option>
                </select>
                <span v-if="validation && validation.depreciation_method" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.depreciation_method[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('status') }}</label>
                <div class="input-group">
                  <select name="" id="status" v-model="data.status" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
                    <option v-for="row in status_list" :value="row.id" :key="row.id">
                      {{ row.title }}
                    </option>
                  </select>
                  <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.status[0] }}
                                    </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <b-tabs content-class="mt-3" class="nav-custom-link">
          <b-tab :title="$t('notes')">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-12 mb-5">
                  <label>{{ $t('assets_info.notes') }}</label>
                  <textarea v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>
                  <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.notes[0] }}
                                    </span>
                </div>
              </div>
            </div>
          </b-tab>

          <b-tab :title="$t('assets_info.straight_line')" v-if="data.depreciation_method == 1">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-4 mb-5">
                  <label>{{ $t('assets_info.period') }}<span class="text-danger">*</span></label>
                  <div class="input-group">
                    <input type="text" v-model="data.period" class="form-control" :class="validation && validation.period ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.period" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.period[0] }}
                                          </span>
                  </div>
                </div>
                <div class="col-lg-4 mb-5">
                  <label>{{ $t('assets_info.period_type') }}</label>
                  <div class="input-group">
                    <select name="" id="period_type_5" v-model="data.period_type" class="custom-select" :class="validation && validation.period_type ? 'is-invalid' : ''">
                      <option v-for="row in period_type_list" :value="row.id" :key="row.id">
                        {{ row.title }}
                      </option>
                    </select>
                    <span v-if="validation && validation.period_type" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.period_type[0] }}
                                    </span>
                  </div>
                </div>
                <div class="col-lg-4 mb-5">
                  <label>{{ $t('assets_info.fixed_depreciation_value') }}</label>
                  <div class="input-group" :class="((validation && validation.fixed_depreciation_val) || (validation && validation.fixed_depreciation_val_currency_id)) ? 'is-invalid' : ''">
                    <input type="number" min="1" v-model="data.fixed_depreciation_val" class="form-control" :class="validation && validation.fixed_depreciation_val ? 'is-invalid' : ''"/>
                    <select name="" id="fixed_depreciation_val_currency_id" v-model="data.fixed_depreciation_val_currency_id" class="custom-select" :class="validation && validation.fixed_depreciation_val_currency_id ? 'is-invalid' : ''">
                      <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                    </select>
                  </div>
                  <span v-if="validation && validation.fixed_depreciation_val" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.fixed_depreciation_val[0] }}
                                    </span>
                  <span v-if="validation && validation.fixed_depreciation_val_currency_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.fixed_depreciation_val_currency_id[0] }}
                                    </span>
                </div>
                <div class="col-lg-4 mb-5">
                  <label>{{ $t('assets_info.depricated_end_date') }}</label>
                  <div class="input-group">
                    <input type="date" v-model="data.depricated_end_date" class="form-control" :class="validation && validation.depricated_end_date ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.depricated_end_date" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.depricated_end_date[0] }}
                                          </span>
                  </div>
                </div>

              </div>
            </div>
          </b-tab>
          <b-tab :title="$t('assets_info.declining_balance')" v-if="data.depreciation_method == 2">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-4 mb-5">
                  <label>{{ $t('assets_info.period') }}<span class="text-danger">*</span></label>
                  <div class="input-group">
                    <input type="text" v-model="data.period" class="form-control" :class="validation && validation.period ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.period" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.period[0] }}
                                          </span>
                  </div>
                </div>
                <div class="col-lg-4 mb-5">
                  <label>{{ $t('assets_info.period_type') }}</label>
                  <div class="input-group">
                    <select name="" id="period_type_1" v-model="data.period_type" class="custom-select" :class="validation && validation.period_type ? 'is-invalid' : ''">
                      <option v-for="row in period_type_list" :value="row.id" :key="row.id">
                        {{ row.title }}
                      </option>
                    </select>
                    <span v-if="validation && validation.period_type" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.period_type[0] }}
                                    </span>
                  </div>
                </div>
                <div class="col-lg-4 mb-5">
                  <label>{{ $t('assets_info.depricated_end_date') }}</label>
                  <input type="date" v-model="data.depricated_end_date" class="form-control" :class="validation && validation.depricated_end_date ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation.depricated_end_date" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.depricated_end_date[0] }}
                                      </span>
                </div>
                <div class="col-lg-4 mb-5">
                  <label>{{ $t('assets_info.depreciation_percentage') }}</label>
                  <input type="number" min="1" v-model="data.depreciation_percentage" class="form-control" :class="validation && validation.depreciation_percentage ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation.depreciation_percentage" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.depreciation_percentage[0] }}
                                          </span>
                </div>

              </div>
            </div>
          </b-tab>

          <b-tab :title="$t('assets_info.unit_of_production')" v-if="data.depreciation_method == 3">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-4 mb-5">
                  <label>{{ $t('assets_info.cost_per_unit') }}</label>
                  <div class="input-group">
                    <input type="number" min="1" v-model="data.cost_per_unit" class="form-control" :class="validation && validation.cost_per_unit ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.cost_per_unit" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.cost_per_unit[0] }}
                                          </span>
                  </div>
                </div>
                <div class="col-lg-4 mb-5">
                  <label>{{ $t('assets_info.unit_name') }}</label>
                  <div class="input-group">
                    <input type="text" v-model="data.unit_name" class="form-control" :class="validation && validation.unit_name ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.unit_name" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.unit_name[0] }}
                                    </span>
                  </div>
                </div>
                <div class="col-lg-4 mb-5">
                  <label>{{ $t('assets_info.depricated_end_date') }}</label>
                  <input type="date" v-model="data.depricated_end_date" class="form-control" :class="validation && validation.depricated_end_date ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation.depricated_end_date" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.depricated_end_date[0] }}
                                      </span>
                </div>
                <div class="col-lg-4 mb-5">
                  <label>{{ $t('assets_info.assest_total_unit') }}</label>
                  <input type="number" min="1" v-model="data.assest_total_unit" class="form-control" :class="validation && validation.assest_total_unit ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation.assest_total_unit" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.assest_total_unit[0] }}
                                          </span>
                </div>

              </div>
            </div>
          </b-tab>
        </b-tabs>


      </div>

      <div class="pl-0 pr-0 mt-5">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>


    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import {mapGetters, mapState} from "vuex";

export default {

  data() {
    return {
      mainRoute: 'accounting/assets-info',
      mainRouteDependency: 'base/dependency',


      accounts_list: [],
      accounts_leaf_list: [],
      employees: [],
      currencies: [],
      taxes: [],
      cash_account_list: [],
      depreciation_method_list: [],
      period_type_list: [],
      status_list: [],

      idEdit: this.$route.params.id ? this.$route.params.id : '',

      data: {
        name: null,
        purchase_date: null,
        in_service_date: null,
        account_id: null,
        location: null,
        employee_id: null,
        useful_life: null,
        notes: null,
        purchase_amount: null,
        purchase_amount_currency_id: null,
        salvage_value: null,
        salvage_value_currency_id: null,
        cash_account_id: null,
        tax1_id: null,
        tax2_id: null,
        depreciation_method: null,
        period: null,
        period_type: 0,
        fixed_depreciation_val: null,
        fixed_depreciation_val_currency_id: null,
        depricated_end_date: null,
        depreciation_percentage: null,
        cost_per_unit: null,
        assest_total_unit: null,
        unit_name: null,
        status: 1,
      },
      isEditing: false,
      validation: null,
      employee: null,
      tax1: null,
      tax2: null,
      cash_account: null,
      account: null,

    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },
  watch: {

    employee: function (val) {
      if (val) {
        this.data.employee_id = val.id;
      } else {
        this.data.employee_id = null;
      }
    },
    tax1: function (val) {
      if (val) {
        this.data.tax1_id = val.id;
      } else {
        this.data.tax1_id = null;
      }
    },
    tax2: function (val) {
      if (val) {
        this.data.tax2_id = val.id;
      } else {
        this.data.tax2_id = null;
      }
    },

  },
  methods: {

    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/accounting/assets-info');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/accounting/assets-info');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data.name = response.data.data.name;
        this.data.purchase_date = response.data.data.purchase_date;
        this.data.in_service_date = response.data.data.in_service_date;
        this.data.account_id = response.data.data.account_id;
        this.data.location = response.data.data.location;
        this.data.employee_id = response.data.data.employee_id;
        this.data.useful_life = response.data.data.useful_life;
        this.data.notes = response.data.data.notes;
        this.data.purchase_amount = response.data.data.purchase_amount;
        this.data.purchase_amount_currency_id = response.data.data.purchase_amount_currency_id;
        this.data.salvage_value = response.data.data.salvage_value;
        this.data.salvage_value_currency_id = response.data.data.salvage_value_currency_id;
        this.data.cash_account_id = response.data.data.cash_account_id;
        this.data.tax1_id = response.data.data.tax1_id;
        this.data.tax2_id = response.data.data.tax2_id;
        this.data.depreciation_method = response.data.data.depreciation_method;
        this.data.period = response.data.data.period;
        this.data.period_type = response.data.data.period_type;
        this.data.fixed_depreciation_val = response.data.data.fixed_depreciation_val;
        this.data.fixed_depreciation_val_currency_id = response.data.data.fixed_depreciation_val_currency_id;
        this.data.depricated_end_date = response.data.data.depricated_end_date;
        this.data.depreciation_percentage = response.data.data.depreciation_percentage;
        this.data.cost_per_unit = response.data.data.cost_per_unit;
        this.data.assest_total_unit = response.data.data.assest_total_unit;
        this.data.unit_name = response.data.data.unit_name;
        this.data.status = response.data.data.status;

        this.employee = response.data.data.employee;
        this.tax1 = response.data.data.tax1;
        this.tax2 = response.data.data.tax2;
        this.cash_account = response.data.data.cash_account;
        this.account = response.data.data.account;

      });
    },
    // updateValue(value) {
    //     this.data.account_id = value
    // },
    updateValueCash(value) {
      this.data.cash_account_id = value ? value.id : null
    },
    updateValueAccount(value) {
      this.data.account_id = value ? value.id : null
    },
    loadOptions() {
    },

    // getAccountParents() {
    //     ApiService.get(this.mainRouteDependency + `/account_parents`).then((response) => {
    //         this.accounts_list = response.data.data;
    //     });
    // },
    getEmployees(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/employees`, {params: {filter: filter}}).then((response) => {
          this.employees = response.data.data;
        });
      } else {
        this.employees = [];
      }
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },

    getTaxes(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/taxes`, {params: {filter: filter}}).then((response) => {
          this.taxes = response.data.data;
        });
      } else {
        this.taxes = [];
      }
    },


    getCashAccount() {
      ApiService.get(this.mainRouteDependency + "/taxes").then((response) => {
        this.cash_account_list = response.data.data;
      });
    },
    getDepreciationMethod() {
      ApiService.get(this.mainRouteDependency + "/depreciation_method").then((response) => {
        this.depreciation_method_list = response.data.data;
      });
    },
    getPeriodType() {
      ApiService.get(this.mainRouteDependency + "/period_type_assets").then((response) => {
        this.period_type_list = response.data.data;
      });
    },
    getStatusList() {
      ApiService.get(this.mainRouteDependency + "/status_assets").then((response) => {
        this.status_list = response.data.data;
      });
    },
    getAccounts(filter) {
      if (filter && filter.length >= 3)
        return ApiService.get(this.mainRouteDependency + `/account_parents_leaf_nodes`, {params: {name: filter}}).then((response) => {
          this.accounts_leaf_list = response.data.data;
        });
    },

    defaultDataForUser() {
      ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.tax1 = response.data.data.tax;
      });
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.assets_info"), route: '/accounting/assets-info'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);


    // this.getAccountParents();
    // this.getEmployees();
    this.getCurrencies();

    this.getCashAccount();
    this.getDepreciationMethod();
    this.getPeriodType();
    this.getStatusList();

    if (this.idEdit) {
      this.getData();
    } else {
      this.defaultDataForUser();
    }
  },
};
</script>


